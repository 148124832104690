import React, { Component } from 'react';

export class Footer extends Component {

    render() {
        return (
            <>
                <footer className="main-footer">
                    <div className="float-right d-none d-sm-block">
                        <b>Portal de Integração</b> 0.1.0
                    </div>
                    <strong>TV Alphaville</strong>
                </footer>
            </>
        );
    }
}

export default Footer;
