import React from 'react'

export default function Alert(props) {
    return (
        <div className={`alert alert-${ props.type }`}>
                  <h5><i className={`icon fas fa-${ props.icon }`}></i> {props.title}</h5>
                  {props.children}
        </div>
    )
}
