import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthService from '../../services/auth/AuthService'

export default function ProtectedRoute(props) {
    if(!AuthService.isAuthenticated()) {
        return <Redirect to="/login" />
    }
    return (
        <Route {...props}/>      
    )
}
