import React, { Component } from 'react'
import Container from '../../components/container'
import Subscriber from '../../services/subscriber/Subscriber'

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSubscriberCount: 0,
            inactiveSubscriberCount: 0,
        }
    }

    componentDidMount(){
        this.getSubscriberCount()
    }

    async getSubscriberCount() {
        let SubscriberRequest = await Subscriber.all()
        if(SubscriberRequest.status === 200){
            let activeCount = 0, inactiveCount = 0
            SubscriberRequest.data.filter(subscriber => subscriber.is_active === true ? activeCount++ : inactiveCount++)
            this.setState({
                activeSubscriberCount: activeCount,
                inactiveSubscriberCount: inactiveCount
            })
        } 
    }

    render() {
        return (
            <>
                <Container page="">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <a href="/assinantes?status=ativo">
                                <div className="info-box bg-success">
                                    <span className="info-box-icon"><i className="fa fa-thumbs-up"/></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Assinantes <strong>ativos</strong></span>
                                        <span className="info-box-number">{this.state.activeSubscriberCount}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <a href="/assinantes?status=bloqueado">
                                <div className="info-box bg-danger">
                                    <span className="info-box-icon"><i className="fa fa-thumbs-down"/></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Assinantes <strong>bloqueados</strong></span>
                                        <span className="info-box-number">{this.state.inactiveSubscriberCount}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}
