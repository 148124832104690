import LocalStorageService from '../localStorage/LocalStorageService'
import config from '../../config/config'
import jwt from 'jsonwebtoken'
import request from '../../utils/request'

class Auth {
    constructor(){
        this.debug = config.debug
    }

    getToken(username, password){
        return request.post('/api/auth', {
            username,
            password
        })
    }

    isAuthenticated(){
        try {
            let accessToken = LocalStorageService.getAccessToken()

            if(accessToken) {
                let decodedToken = jwt.decode(accessToken)
                if(this.debug) console.debug(`Decoded token: ${JSON.stringify(decodedToken)}`)
    
                let now = Math.ceil(Date.now()/1000) //It's needed to convert from miliseconds to seconds
                let expiredToken = now >= decodedToken.exp // Token exp time is in seconds
                if(!expiredToken){
                    return true
                }
            }

            LocalStorageService.clear()
            return false
        } catch(e) {
            console.error(e)
            LocalStorageService.clear()
            return false
        }
    }

    resetPassword(oldPassword, newPassword, confirmPassword){
        return request.post(`/api/password/change`, {
          "old_password": oldPassword,
          "new_password": newPassword,
          "confirmPassword": confirmPassword
        }, {
          headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`
          }
        })
    }
}

export default new Auth()