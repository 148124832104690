import React, { Component } from 'react'
import Container from '../../components/container'

export default class Help extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    async componentDidMount(){

    }

    render() {
        return (
            <>
                <Container page="">
                    <h1>Ajuda</h1>
                    <p align="justify">
                        Reprehenderit mollit ipsum nisi pariatur ad. Cupidatat minim excepteur sint ipsum nisi anim labore. Pariatur dolor laborum consequat magna quis aliquip anim commodo pariatur minim. Aute exercitation mollit pariatur incididunt nostrud anim non ex velit.
                    </p>
                    <h3>Serviços disponíveis</h3>
                    <p align="justify">Occaecat aliqua voluptate cillum ullamco id. Esse est magna nulla amet ut quis ullamco velit commodo. Tempor cupidatat dolore eu reprehenderit Lorem ipsum aute in consequat mollit ea irure mollit esse. Ipsum magna pariatur velit sint tempor quis laboris veniam nulla irure incididunt laboris.</p>
                    <h3>Cadastrar um assinante</h3>
                    <p align="justify">Aliquip minim nostrud do in culpa qui adipisicing. Exercitation et consectetur eiusmod cillum qui proident nisi officia deserunt culpa cillum pariatur voluptate laboris. Dolor esse enim sunt esse exercitation est dolor nostrud minim exercitation reprehenderit magna consequat labore. Dolore mollit incididunt amet ad incididunt aliquip minim deserunt esse fugiat laboris sunt. Fugiat dolore magna cupidatat ea cillum ea do commodo. Id cillum id ex non sit aliqua cillum cupidatat consequat sint consectetur exercitation reprehenderit. Qui commodo eiusmod do proident eu culpa consectetur elit reprehenderit mollit aute. Laboris elit in minim nostrud ea amet amet fugiat ipsum cupidatat culpa eu consectetur. Excepteur aliquip magna sint consequat fugiat eu fugiat laborum ex ea. Et officia culpa cillum veniam nostrud laboris veniam.</p>
                    <h3>Bloquear um assinante</h3>
                    <p align="justify">Minim duis eiusmod exercitation aliquip enim. Exercitation nisi irure ea non sit cillum sit aliquip aliqua pariatur ad ut laboris. Duis aute in deserunt dolore id laborum est. Aute cillum sunt cupidatat cupidatat qui fugiat occaecat nisi qui tempor. Sunt nostrud sint est consectetur.</p>
                </Container>
            </>
        )
    }
}
