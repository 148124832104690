import React, { Component } from 'react'

import Container from '../../components/container'

import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'

import LocalStorageService from '../../services/localStorage'
import '../../services/service'
import Service from '../../services/service'


export default class Services extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceList: []
        }
    }

    componentDidMount() {
        this.getServices()
    }

    async getServices() {
        let ServiceRequest = await Service.all()
        if(ServiceRequest.status === 200) {
            ServiceRequest.data.find(service => service.name.toLowerCase() === 'funkids').description = "Aplicativo mobile voltado ao público infantil, com jogos, desenhos e muito mais."
            this.setState({
                serviceList: ServiceRequest.data
            })
        } else {
            LocalStorageService.clear()
        }
    }

    renderRows(service) {
        return (
            <tr key={service.id}>
                <td>{service.name}</td>
                <td>{service.description}</td>
            </tr>
        )
    }

    render() {
        return (
            <>
                <Container>
                    <Card className="card card-outline card-dark">
                        <Card.Header className="card-header">
                            <h3 className="card-title">Serviços</h3>
                            <div className="card-tools">
                                <div className="input-group input-group-sm" style={{width: 150}}>
                                    <input type="text" name="table_search" className="form-control float-right" placeholder="Pesquisa" />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-default"><i className="fas fa-search" /></button>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Descrição</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.serviceList.map(service => this.renderRows(service))}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer className="card-footer clearfix">
                            <div className="card-tools">
                                
                            </div>
                        </Card.Footer>
                    </Card>
                </Container>
            </>
        )
    }
}
