import React, {useState} from 'react'
import LocalStorageService from '../../services/localStorage'
import './Login.css'
import whiteLogo from '../../assets/tvalphaville-logo-branco.png'

import AuthService from '../../services/auth/AuthService'

import If from '../../components/operator/If'
import Alert from '../../components/alert'
import Loading from '../../components/loading'

export default function Login(props) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setLoading(true)
      let loginRequest = await AuthService.getToken(username, password)
      if(loginRequest.status === 200) {
        setErrorMessage(null)
        LocalStorageService.setAccessToken(loginRequest.data.token)
        LocalStorageService.setUserData(username)
        window.location.pathname = '/home'
      }
    }
    catch (e){
      setLoading(false)
      if(e.request.status){
        LocalStorageService.clear()
        if(e.request.status !== 401){
          setErrorMessage("Isto é embaraçoso, mas pareço estar com um mau funcionamento. Por favor, avise meu mestre. =(")
        } else {
          setErrorMessage("Verifique seu usuário e senha.")
        }
      }
    }
  }

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  if(AuthService.isAuthenticated()){
    window.location.pathname = '/home'
  }

  return (
    <div className="login-page">
      <img src={whiteLogo} className="img-fluid pb-5" alt="TV Alphaville"/>
      <div className="login-box">
        <div className="login-logo">
          <a href="/home" style={{color:'white'}}>Portal de Integração</a>
        </div>
        <div className="card">
        <If test={loading}>
          <Loading />
        </If>
          <div className="card-body login-card-body">
            <p className="login-box-msg">Faça login para usar o sistema</p>
            <If test={!!errorMessage}>
              <Alert type="danger" icon="ban">
                <p>{errorMessage}</p>
              </Alert>
            </If>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Usuário"
                  name="username"
                  onChange={e => setUsername(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Senha"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" disabled={!validateForm()}>Entrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
