import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import MainLayout from './components/layout/MainLayout'

import Home from './pages/home'
import Login from './pages/login'
import ResetPassword from './pages/resetPassword'
import Subscribers from './pages/subscribers'
import Services from './pages/services'
import Help from './pages/help'

export default function Routes() {
    return (
        <Switch>
            <MainLayout path="/home" exact component={Home} />
            <MainLayout path="/assinantes" exact component={Subscribers} />
            <MainLayout path="/servicos" exact component={Services} />
            <MainLayout path="/ajuda" exact component={Help} />
            <Route path="/login" exact component={Login} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Redirect from="*" to="/home" />
        </Switch>
    )
}
