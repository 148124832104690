
import request from '../../utils/request'
import LocalStorageService from '../../services/localStorage'

class Subscriber {
    all(){
        return request.get('/api/subscription', {
          headers: {
            Authorization: "Bearer " + LocalStorageService.getAccessToken()
          }
        })
    }

    find(id){
        return request.get('/api/subscription/' + id, {
          headers: {
            Authorization: "Bearer " + LocalStorageService.getAccessToken()
          }
        })
    }

    create(subscriber) {
        let config = {
          headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`
          }
        }
        let params = {
          service_id: subscriber.service_id,
          username: subscriber.username,
          password: subscriber.password,
          is_active: true
        }
        return request.post('/api/subscription', params, config)
    }

    update(subscriber) {
        let config = {
          headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`
          }
        }
        let params = {
          username: subscriber.username,
          is_active: subscriber.is_active
        }
        return request.put('/api/subscription/' + subscriber.id, params, config)
    }
}


export default new Subscriber()