import React, {useState} from 'react'
import './ResetPassword.css'
import { Redirect } from 'react-router-dom'

//import {resetPassword} from '../../services/auth'
import AuthService from '../../services/auth/AuthService'

import If from '../../components/operator/If'
import Alert from '../../components/alert'
import Loading from '../../components/loading'
import LocalStorageService from '../../services/localStorage/LocalStorageService'


export default function Login(props) {
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  if(!AuthService.isAuthenticated()) {
    return <Redirect to="/login" />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      let user = LocalStorageService.getUserData()
      setLoading(true)
      let resetPasswordRequest = await AuthService.resetPassword(user.id, password)
      setLoading(false)
      if(resetPasswordRequest.status === 200) {
        LocalStorageService.setUserData(resetPasswordRequest.data)
        window.location.pathname = '/home'
      }
    } catch(e) {
      console.log(e.response.data.detail)
      setErrorMessage(e.response.data.detail)
      if(e.request.status === 401) {
        window.location.href = "/login"
      }
      setLoading(false)
    }
  }

  function validateForm() {
    return password.length > 0 && password === passwordConfirmation;
  }

  return (
    <div className="login-page">
    <div className="login-box">
      <div className="login-logo">
        <a href="/home">Netman</a>
      </div>
      <div className="card">
        <If test={loading}>
          <Loading />
        </If>
        <div className="card-body login-card-body">
          <p className="login-box-msg">
          </p>
          <Alert type="warning" icon="exclamation">
            Parece que você precisa <strong>redefinir</strong> a sua senha antes de continuar.
          </Alert>
          <If test={!!errorMessage}>
            <Alert type="danger" icon="ban">
              <p>{errorMessage}</p>
            </Alert>
          </If>
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Senha"
                name="password"
                onChange={e => setPassword(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Confirmação da senha"
                name="passwordConfirmation"
                onChange={e => setPasswordConfirmation(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block" disabled={!validateForm()}>Redefinir</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  )
}
