import React from 'react';
import { NavLink } from 'react-router-dom'

import whiteLogo from '../../assets/tvalphaville-logo-branco.png'

import Counter from './Counter'
import LocalStorageService from '../../services/localStorage'
import jwt from 'jsonwebtoken'

export default function Header() {
  let tokenExpirationTimeInSeconds
  try{
    tokenExpirationTimeInSeconds = jwt.decode(LocalStorageService.getAccessToken()).exp
  } catch(e) {

  }

  function handleLogout() {
    LocalStorageService.clear()
  }

  return (
    <nav className="main-header navbar navbar-expand-md navbar-dark navbar-gray-dark">
      <div className="container">
        <a href="/home" className="navbar-brand">
          <img src={whiteLogo} alt="TV Alphaville" className="brand-image" style={{opacity: '.9'}} />
        </a>
        <button className="navbar-toggler order-1 collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="navbar-collapse order-3 collapse" id="navbarCollapse" style={{}}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/home" className="nav-link" activeClassName="active" exact>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/assinantes" className="nav-link" activeClassName="active" exact>Assinantes</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/servicos" className="nav-link" activeClassName="active" exact>Serviços</NavLink>
            </li>
          </ul>
        </div>
        <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
          <li>
              <span className="dropdown-header"><small>Tempo restante: </small><strong><Counter initialTime={tokenExpirationTimeInSeconds}/></strong></span>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="/">
              <i className="fas fa-user" />
              <i className="fas fa-chevron-down" />
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              {/* <span className="dropdown-item dropdown-header">Alterar senha</span> */}
              <div className="dropdown-divider"></div>
              <a href="/" className="dropdown-item dropdown-footer" onClick={handleLogout}>Sair</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
  
}
