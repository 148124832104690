import React, { Component } from 'react';

export class Container extends Component {

    render() {
        return (
            <>
                <div className="content-wrapper">
                    <section className="content-header mx-md-5">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>{this.props.page}</h1>
                                </div>
                                <div className="col-sm-6">
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content mx-md-5">
                        <div className="container-fluid">
                            {this.props.children}
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default Container;
