
import request from '../../utils/request'
import LocalStorageService from '../../services/localStorage'

class Service {
    all(){
        return request.get('/api/service', {
          headers: {
            Authorization: "Bearer " + LocalStorageService.getAccessToken()
          }
        })
    }
}

export default new Service()