import React from 'react';
import ReactDOM from 'react-dom';

import 'admin-lte/plugins/pace-progress/pace'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle'
import 'admin-lte/dist/js/adminlte'

import './index.css';
import 'admin-lte/plugins/pace-progress/themes/green/pace-theme-flat-top.css'
import 'admin-lte/dist/css/adminlte.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

// eslint-disable-next-line
import localization from 'moment/locale/pt-br'

import App from './App';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
