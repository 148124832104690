import React, { Component } from 'react'
import moment from 'moment'

export default class Counter extends Component {
    constructor(props){
        super(props)
        this.state = {
            remainingTime: 0
        }
    }

    componentDidMount() {
        this.counterIntervalId = setInterval(() => this.tick(), 1000)
    }

    componentWillUnmount(){
        clearInterval(this.counterIntervalId)
    }

    tick() {
        this.setState({
            remainingTime: moment(this.props.initialTime*1000).fromNow(true)
        })
    }

    render() {
        return (
            <>
                {this.state.remainingTime}
            </>
        )
    }
}