import React, { Component } from 'react'

import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from '../../components/container'
import If from '../../components/operator/If'
import Loading from '../../components/loading'
import ReactPaginate from 'react-paginate'

import LocalStorageService from '../../services/localStorage'
import Subscriber from '../../services/subscriber'
import Service from '../../services/service'

export default class Subscribers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subscribers: [],
            services: [],
            loading: false,
            showUpdateModal: false,
            showAddModal: false,
            offset: 0,
            elements: [],
            perPage: 10,
            currentPage: 0,
            subscriberFilter: '',
            filteredSubscribers: [],
            subscriberToEdit: {},
            subscriberToAdd: {
                service_id: 0,
                username: "",
                password: "",
                is_active: true
            }
        }
    }

    componentDidMount() {
        this.params = new URLSearchParams(this.props.location.search)
        this.getServices()
        this.getSubscribers()
    }

    async getSubscribers() {
        let subscriberRequest = await Subscriber.all()
        let subscribers = []
        let status = this.params.get('status') || ""
        if(subscriberRequest.status === 200) {
            if(status.toUpperCase() === "ATIVO"){
                subscribers = subscriberRequest.data.filter(subscriber => subscriber.is_active === true)
            } else if (status.toUpperCase() === "BLOQUEADO") {
                subscribers = subscriberRequest.data.filter(subscriber => subscriber.is_active === false)
            } else {
                subscribers = subscriberRequest.data
            }
            this.setState({
                subscribers: subscribers,
                filteredSubscribers: subscribers,
                pageCount: Math.ceil(subscribers.length / this.state.perPage)
            }, () => this.setElementsForCurrentPage())
        } else {
            LocalStorageService.clear()
        }
    }    

    async updateSubscriber(subscriber) {
        let updateSubscriberRequest = await Subscriber.update(subscriber)
        if(updateSubscriberRequest.status === 200) {
            this.getSubscribers()
            this.handleUpdateClose()
        } else {
            LocalStorageService.clear()
        }
        this.setState({
            loading: false
        })
    }

    async createSubscriber(subscriber) {
        try {
            let createSubscriberRequest = await Subscriber.create(subscriber)
            if(createSubscriberRequest.status === 201) {
                this.getSubscribers()
                this.handleAddClose()
            }
        } catch (e) {
            if(e.response.status === 400) {
                let subscriber = this.state.subscriberToAdd
                subscriber.hasUsernameError = true
                subscriber.usernameErrorMessage = "Usuário já cadastrado para este serviço."
                this.setState({
                    subscriberToAdd: subscriber
                })
                console.log("Usuario ja cadastrado")
            } else {
                LocalStorageService.clear()
            }
            this.setState({
                loading: false
            })
        }
    }

    async getServices() {
        this.setState({loading:true}) //It will get finished loading after setElementsForCurrentPage funcion finishes
        let ServiceRequest = await Service.all()
        if(ServiceRequest.status === 200) {
            this.setState({
                services: ServiceRequest.data
            })
        } else {
            LocalStorageService.clear()
        }
    }

    setElementsForCurrentPage(){
        let elements = this.state.filteredSubscribers.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            elements: elements,
            pageCount: Math.ceil(this.state.filteredSubscribers.length / this.state.perPage)
        })
        this.setState({loading:false})
    }

    renderSubscriberRow(subscriber) {
        return (
            <tr key={subscriber.id} onClick={() => this.handleUpdateShow(subscriber)}>
                <td>{subscriber.username}</td>
                <td>FunKids</td>
                <td><span className={subscriber.is_active ? "badge badge-success" : "badge badge-danger"}>{subscriber.is_active?"Ativo":"Bloqueado"}</span></td>
            </tr>
        )
    }

    subscriberFilter(){
        this.setState({
            filteredSubscribers: this.state.subscribers.filter(subscriber => subscriber.username.startsWith(this.state.subscriberFilter)),
        }, () => {this.setElementsForCurrentPage()})
    }

    handleUpdateShow(subscriber) {
        this.setState({
            showUpdateModal: true,
            subscriberToEdit: {
                id: subscriber.id,
                username: subscriber.username,
                is_active: subscriber.is_active
            }
        })
    }

    handleAddShow() {
        this.setState({
            showAddModal: true
        })
    }

    handleUpdateClose() {
        this.setState({
            showUpdateModal: false,
            subscriberToEdit: {},
            loading: false
        })
    }

    handleAddClose() {
        this.setState({
            showAddModal: false,
            subscriberToAdd: {
                service_id: 0,
                username: "",
                password: "",
                is_active: true
            },
            loading: false
        })
    }

    handlePageClick = (data) => {
        this.setState({loading:true})
        const selectedPage = data.selected
        const offset = (selectedPage * this.state.perPage)
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {this.setElementsForCurrentPage()})
    }

    handleFilter = (e) => {
        this.setState({
            subscriberFilter: e.target.value
        }, () => {this.subscriberFilter()})
    }

    handleIsActive = (e) => {
        let subscriberToEdit = this.state.subscriberToEdit
        subscriberToEdit.is_active = !subscriberToEdit.is_active
        this.setState({
            subscriberToEdit: subscriberToEdit
        })
    }

    handleSave() {
        this.setState({
            loading: true //Will be set to false after update on updatesubscriber function
        })
        if(this.state.subscriberToEdit === {}) {
            this.handleUpdateClose()
        }
        else {
            this.updateSubscriber(this.state.subscriberToEdit)
        }
    }

    handleAdd(){
        let subscriber = this.state.subscriberToAdd
        console.log(subscriber)
        if(parseInt(subscriber.service_id) === 0){
            subscriber.hasServiceError = true
            this.setState({
                subscriberToAdd: subscriber
            })
        } else if (subscriber.username.length < 1) {
            subscriber.hasUsernameError = true
            this.setState({
                subscriberToAdd: subscriber
            })
        } else if (subscriber.password.length < 1) {
            subscriber.hasPasswordError = true
            this.setState({
                subscriberToAdd: subscriber
            })
        } else {
            this.setState({
                loading: true
            })
            this.createSubscriber(subscriber)
        }
    }

    handleAddUsername(username){
        let subscriber = this.state.subscriberToAdd
        subscriber.username = username
        subscriber.hasUsernameError = false
        this.setState({
            subscriberToAdd: subscriber
        })
    }

    handleAddPassword(password){
        let subscriber = this.state.subscriberToAdd
        subscriber.password = password
        subscriber.hasPasswordError = false
        this.setState({
            subscriberToAdd: subscriber
        })
    }

    handleServiceSelectChange = (e) => {
        let subscriber = this.state.subscriberToAdd
        subscriber.service_id = e.target.value
        subscriber.hasServiceError = false
        this.setState({
            subscriberToAdd: subscriber
        })
    }

    render() {
        let paginationElement
        if(this.state.pageCount > 1) {
            paginationElement = (
                <ReactPaginate
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    previousLabel={"Voltar"}
                    nextLabel={"Avançar"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={this.state.pageCount}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage}
                    containerClassName={"pagination pagination-sm float-right"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}

                />
            )
        }
        
        const serviceOptionList = this.state.services.map(service => 
            <option key={service.id} value={service.id}>{service.name}</option>
        )

        return (
            <>
                <Container>
                    <Card className="card card-outline card-dark">
                        <If test={this.state.loading}>
                            <Loading />
                        </If>
                        <Card.Header className="card-header">
                            <h3 className="card-title">Assinantes</h3>
                            <div className="card-tools">
                                <ul className="pagination pagination-sm float-left">
                                    <li className="page-item"><button className="page-link" onClick={() => this.handleAddShow()}><i className="fas fa-upload"  style={{color: "#343A40"}}/></button></li>
                                    <li className="page-item"><button className="page-link" onClick={() => this.handleAddShow()}><i className="fas fa-user-plus" style={{color: "#343A40"}}/></button></li>
                                </ul>
                                <div className="input-group input-group-sm pl-2" style={{width: 150}}>
                                    <input type="text" name="table_search" className="form-control float-right" placeholder="Pesquisa"
                                        value={this.state.subscriberFilter}
                                        onChange={this.handleFilter}
                                    />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-default"><i className="fas fa-search" /></button>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table striped hover className="table table-sm table-responsive-sm">
                                <thead className="thead-dark">
                                    <tr>
                                        <th style={{'minWidth':'150px'}}>Usuário</th>
                                        <th style={{'maxWidth':'90px'}}>Serviço</th>
                                        <th style={{'minWidth':'10%'}}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <If test={this.state.elements.length === 0}>
                                        <tr style={{'width': '100%'}}>
                                            <td colSpan="3">
                                                <center>Sem correspondências.</center>
                                            </td>
                                        </tr>
                                    </If>
                                    {this.state.elements.map(subscriber => this.renderSubscriberRow(subscriber))}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer className="card-footer clearfix">
                            <div className="card-tools">
                                {paginationElement}
                            </div>
                        </Card.Footer>
                    </Card>
                </Container>

                <Modal show={this.state.showUpdateModal} onHide={() => this.handleUpdateClose()}>
                    <If test={this.state.loading}>
                        <Loading />
                    </If>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.subscriberToEdit.username}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="updateSubscriber">
                                <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" defaultChecked={this.state.subscriberToEdit.is_active} onChange={this.handleIsActive} className="custom-control-input" id="isActive" />
                                    <label className="custom-control-label" htmlFor="isActive">{this.state.subscriberToEdit.is_active?"Ativo":"Bloqueado"}</label>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleUpdateClose()}>
                            Fechar
                        </Button>
                        <Button variant="success" onClick={() => this.handleSave()}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showAddModal} onHide={() => this.handleAddClose()}>
                    <If test={this.state.loading}>
                        <Loading />
                    </If>
                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar assinatura</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="addSubscriber">
                                <div className="form-group">
                                    <label>Serviço</label>
                                    <select value={this.state.subscriberToAdd.service_id} onChange={this.handleServiceSelectChange} className={"form-control " + (this.state.subscriberToAdd.hasServiceError ? "is-invalid " : "")}>
                                        <option value={0}>Escolha um serviço</option>
                                        {serviceOptionList}
                                    </select>
                                    <span id="service-error" className="error invalid-feedback">Por favor, selecione um serviço.</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username">Usuário</label>
                                    <input type="text" required onChange={(e) => this.handleAddUsername(e.target.value)} className={"form-control " + (this.state.subscriberToAdd.hasUsernameError ? "is-invalid " : "")} id="username" placeholder="Digite o nome de usuário para o ser utilizado no aplicativo." />
                                    <span id="username-error" className="error invalid-feedback">
                                        {this.state.subscriberToAdd.usernameErrorMessage? this.state.subscriberToAdd.usernameErrorMessage:"Por favor, informe um usuário."}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Senha</label>
                                    <input type="text" required onChange={(e) => this.handleAddPassword(e.target.value)} className={"form-control " + (this.state.subscriberToAdd.hasPasswordError ? "is-invalid " : "")} id="password" placeholder="Digite a senha inicial para ser utilizada no aplicativo." />
                                    <span id="password-error" className="error invalid-feedback">Por favor, informe uma senha.</span>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleAddClose()}>
                            Fechar
                        </Button>
                        <Button variant="success" onClick={() => this.handleAdd()}>
                            Adicionar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
