import React from 'react'
import ProtectedRoute from '../protectedRoute'

import Header from '../header'
import Footer from '../footer'


export default function MainLayout(props) {
    return (
        <div className="wrapper">
            <Header />
            <ProtectedRoute {...props} />
            {props.children}
            <Footer />
        </div>
    )
}
