import config from '../../config'

/**
 * This class provides methods to storage and retrieve
 * information from localstorage 
 * 
 */
class LocalStorageService {
    constructor(){
        this.debug = config.debug || false
        this.accessTokenPrefix = "_tva_access_token"
        this.userDataPrefix = "_tva_user"
    }
    
    clear(){
        if(this.debug) console.debug("Cleaning up localstorage.")
        localStorage.clear()
        return true
    }

    store(key, value, stringfy=false){
        if(this.debug) console.debug(`Storing ${key} as key and ${value} as value.`)
        if(stringfy){
            if(this.debug) console.debug(`Parsing ${value} into JSON.`)
            localStorage.setItem(key, JSON.stringify(value))
        }else{
            localStorage.setItem(key, value)
        }
    }

    retrieve(key, parseJson = false){
        let obj = localStorage.getItem(key)
        if(this.debug) console.debug(`Retrieving ${key} as key and ${obj} as value.`)
        if(this.debug && parseJson) console.debug(`Parsing ${obj} into object.`)
        if(parseJson) return JSON.parse(obj)
        return obj
    }

    setToken(token){
        this.setAccessToken(token.access)
        this.setRefreshToken(token.refresh)
        return token
    }

    setAccessToken(token){
        this.store(this.accessTokenPrefix, token)
        return true
    }

    getAccessToken(){
        return this.retrieve(this.accessTokenPrefix)
    }
    
    setUserData(userData){
        this.store(this.userDataPrefix, userData)
        return true
    }

    getUserData(){
        return this.retrieve(this.userDataPrefix)
    }
}

export default new LocalStorageService()